import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Energy, Products, Tariff, Cost, Suppliers, STSC } from 'stsc-tsc';


@Injectable({
  providedIn: 'root'
})
export class StscEngineService {

  stsc: STSC
  // holds the data sourced from the meter (Glow Service)
  energy: Energy;

  // any corrections to data to fill in gaps historically
  // estimate: Energy;

  // prediction of energy use - including scenarios
  prediction: Energy;

  products: Products;
  suppliers: Suppliers;
  cost: Cost;
  tariff: Tariff;

  private TariffAnnouncer = new Subject<any>();
  allTariffs$ = this.TariffAnnouncer.asObservable();

  constructor() {
    //localStorage.setItem('gsp', 'J');

    this.products = new Products();

    this.suppliers = new Suppliers();
    this.suppliers.load().then(async () => {

      this.products.setSuppliers(this.suppliers);
      this.products.token = localStorage.getItem('id_token');

      // this.stsc.token = localStorage.getItem('id_token')
      // this.products.gsp = this.stsc.loadgsp().then(gsp => {
      //   console.log(gsp,)
      // })
    
      this.products.gsp = 'J'

      this.energy = new Energy();
      this.energy._token = localStorage.getItem('id_token');
      await this.energy.load()
      console.log('this.energy', this.energy)

      this.products.load().then(() => {
        this.products.loadtariffs().then(async () => {

          console.log('this.products.tariffs',this.products.tariffs);

          this.prediction = this.energy;
          console.log('this.prediction',this.prediction);

          console.log("loaded energy");
          this.products.predictedEnergy = this.energy;

          // this.tariff = new Tariff();
          this.cost = new Cost();
          console.log('this.products',this.products);

          // this.energy.profile;
          // this.products.calcCosts().then(() => {
          await this.products.calcCosts()
          this.products.sortProducts();
          this.products.calcSmart();
          console.log("loaded energy");
          // anounce all tariffs
          this.TariffAnnouncer.next(this.products.tariffs);
          // });
        });
      });
    });

    // this.products.loadtariffs().then(()=>{
    //   this.products.predictedEnergy = this.energy;
    //   this.cost = new Cost();
    //   this.tariff = new Tariff();
    // });

  }

  public pickResult() {

  }

  public useByDay() {
    // different aggregate and time ranges can be supported, although
    // default to mean and whole energy data set
    let params = {
      aggregateFunction: Energy.AGG_MEAN,
    };

    return this.energy.byHHSlotDOW(params);
  }

  public predictedUseByDay() {
    // different aggregate and time ranges can be supported, although
    // default to mean and whole energy data set
    let params = {
      aggregateFunction: Energy.AGG_MEAN,
    };

    return this.prediction.byHHSlotDOW(params);
  }

  public async clearPrediction() {
    this.prediction = this.energy;
    await this.products.calcCosts()
  }

  public filterTariffs(criteria) {
    this.products.matchTariffs(criteria);
    console.log('this.products.filteredTariffs',this.products.filteredTariffs);
  }

  public sortCost() {
    this.products.sortProducts();
  }

  public sortSmart() {
    this.products.sortSmart();
  }

  public addEV() {
    // model parameters can be changed, but defaults
    // are 4 hour period over night using 6kW charger
    let params = {
      start: 1,
      end: 10,
      amount: 3000
    };

    this.prediction = this.energy.addEnergy(params);

  }

  public timeShiftEnergy(percent) {

    let params = {
      from: { start: 36, end: 42 },
      to: { start: 0, end: 6 },
      amount: percent
    };

    this.prediction = this.energy.shiftEnergy(params);

  }

  public likeProduct(product) {

    product['like'] = product['like'] ? false : true;

  }

  getEnergy() {
    return this.energy;
  }

}
