<nb-layout windowMode>

  <nb-layout-header style="background-color: #F7F9FC;" fixed>

    <div class="row card-row max">
      <div class="col">
        <div class="header-container">
          <div class="logo-container">
            <a class="logo" (click)="navigateHome(loginStatus)"><img src="assets/logo-dark.svg" /></a>
          </div>
          <nb-actions size="small" class="max">
            <nb-action class="control-item"></nb-action>
            <nb-action class="user-action control-item" *ngIf="name" (click)="navigateResults()">{{name}}</nb-action>
          </nb-actions>
        </div>
      </div>

      <div class="col right" style="text-align: right;">
        <div class="login-btn-wrapper">
          <nb-icon *ngIf="url!='/home' && url!='/results'" (click)="toggleMenuSidebar()" icon="menu-outline"></nb-icon>
          <button *ngIf="loginStatus!='logged in'" nbButton class="login-btn" (click)="doLogIn()">{{"LOGIN" |
            translate}}</button>
          <button *ngIf="loginStatus=='logged in'" nbButton class="login-btn" (click)="doLogOut()">{{"LOGOUT" |
            translate}}</button>
        </div>
      </div>
    </div>

  </nb-layout-header>

  <nb-sidebar *ngIf="url!='/home' && url!='/results'" tag="right" right responsive>
    <span *ngIf="name">{{name}}</span>
    <nb-menu [items]="menu"></nb-menu>
  </nb-sidebar>

  <nb-sidebar *ngIf="url=='/results'"
    [ngClass]="filterActive ? 'result-filter-sidebar active' : 'result-filter-sidebar inactive' " tag="menu-sidebar"
    responsive>

    <strong class="menu-title">{{"SET_CRITERIA" | translate}} <nb-icon class="close" (click)="toggleFilterSidebar()" icon="close-circle-outline"></nb-icon></strong>

    <div class="row row-head">
      <div class="col"><span>Contract</span></div>
    </div>

    <div class="row">
      <div class="col"><strong>Tariff Type</strong></div>
    </div>

    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 3, contractCategory, tariffItem, { 'type': 'flat' })"
          status="basic">Fixed</nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 4, contractCategory, tariffItem, { 'is_variable': true })"
          status="basic">Flexible/Variable
        </nb-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 5, contractCategory, tariffItem, { 'type': 'tou' })"
          status="basic">Time of Use
        </nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 6, contractCategory, tariffItem, { 'type': 'dynamic' })"
          status="basic">Dynamic</nb-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col"><strong>Green</strong></div>
    </div>

    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 2, contractCategory, greenItem, { 'green': true })"
          status="basic">100% renewable energy
        </nb-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col"><strong>Contract length</strong></div>
    </div>

    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 7, contractCategory, contractItem, { 'term': null })"
          status="basic">None</nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 8, contractCategory, contractItem, { 'term': 12 })"
          status="basic">12 months
        </nb-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 9, contractCategory, contractItem, { 'term': 24 })"
          status="basic">24 months
        </nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 9, contractCategory, contractItem, { 'term': 99 })"
          status="basic">24+ months
        </nb-checkbox>
      </div>

    </div>

    <div class="row">
      <div class="col"><strong>Exit fee</strong></div>
    </div>

    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 10, contractCategory, exitFeeItem, { 'exitFees': 0 })"
          status="basic">No exit fee
        </nb-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col"><strong>Supplier rating</strong></div>
    </div>
    <div class="row">
      <div class="col">
        <stsc-input-rating (changeRating)="changeRating($event)"></stsc-input-rating>
      </div>
    </div>

    <div class="row row-head">
      <div class="col"><span>Low Carbon Technology</span></div>
    </div>
    <div class="row">
      <div class="col"><strong>Have or considering?</strong></div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 11, lowCarbonCategory, haveOrConsidering, { 'is_solar': true })"
          status="basic">Solar</nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 12, lowCarbonCategory, haveOrConsidering, { 'is_ev': true })"
          status="basic">EV</nb-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 13, lowCarbonCategory, haveOrConsidering, { 'is_heatpump': true })"
          status="basic">Heat Pump</nb-checkbox>
      </div>
    </div>

    <div class="row row-head">
      <div class="col"><span>Smart</span></div>
    </div>
    <div class="row">
      <div class="col"><strong>Smart meter</strong></div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 14, meterTypeCategory, smartMeterItem, { 'is_smart': true })"
          status="basic">Smart electric
        </nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 16, meterTypeCategory, smartMeterItem, { 'is_prepay': true })"
          status="basic">Smart prepay
        </nb-checkbox>
      </div>
    </div>

    <div class="row row-head">
      <div class="col"><span>Price</span></div>
    </div>
    <div class="row">
      <div class="col"><strong>Price guarantee for contract</strong></div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 3, priceCategory, guaranteeItem, { 'is_variable': false })"
          status="basic">Fixed rate
        </nb-checkbox>
      </div>
      <div class="col"></div>
    </div>
    <!-- <div class="row">
      <div class="col"><strong>Payment types</strong></div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 20, priceCategory, paymentTypeItem, { 'paymenttype': 'dd' })"
          status="basic">Direct debit
        </nb-checkbox>
      </div>
      <div class="col">
        <nb-checkbox
          (checkedChange)="toggleFeature($event, 21, priceCategory, paymentTypeItem, { 'paymenttype': 'demand' })"
          status="basic">On demand
        </nb-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 22, priceCategory, paymentTypeItem, { 'is_prepay': true })"
          status="basic">Prepay</nb-checkbox>
      </div>
      <div class="col"></div>
    </div> -->
    <!-- <div class="row">
      <div class="col"><strong>Price</strong></div>
    </div>
    <div class="row">
      <div class="col">
        <nb-checkbox (checkedChange)="toggleFeature($event, 23, priceCategory, priceItem, { 'cheaper': true })"
          status="basic">Less than my current
          cost</nb-checkbox>
      </div>
      <div class="col">

      </div>
    </div> -->


  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
    <div *ngIf="url=='/results'" [ngClass]="filterActive ? 'feedback-btn active' : 'feedback-btn inactive' " (click)="toggleFilterSidebar()">
      Filters&nbsp;<nb-icon class="float-right" icon="options-2-outline"></nb-icon>
    </div>
  </nb-layout-column>

  <nb-layout-footer class="dark">
    <div class="row card-row">
      <div class="col">
        <nb-layout-column>
          <h6 class="text-subtitle-1">{{"FAQS" | translate}}</h6>
          <ul class="text-list">
            <li *ngFor="let faq of faqs; index as i;"><a routerLink="/faqs/{{faq.id}}">{{faq.question | translate}}</a>
            </li>
          </ul>
        </nb-layout-column>
      </div>
      <div class="col">
        <nb-layout-column>
          <h6 class="text-subtitle-1">About Smart Tariff</h6>
          <ul class="text-list">
            <li><a href="/who-we-are">Who we are</a></li>
            <li><a href="/unbiased">Unbiased</a></li>
            <li><a href="/what-next">What next</a></li>
            <li><a href="/glossary/default">Glossary</a></li>
            <li><a href="/feedback">Provide feedback</a></li>
          </ul>
        </nb-layout-column>
      </div>
      <div class="col">
        <nb-layout-column>
          <h6 class="text-subtitle-1">Privacy and data</h6>
          <ul class="text-list">
            <li><a href="/your-data-use">How we use your data</a></li>
            <li><a href="/stop-data-collection">Tell us to stop collecting your smart meter data</a></li>
            <li><a href="/your-data-rights">Your rights with your data</a></li>
          </ul>
        </nb-layout-column>
      </div>
    </div>
  </nb-layout-footer>

  <nb-layout-footer class="dark img-footer">
    <div class="row card-row" style="width: 100%">
      <div class="col" style="width: 100%; text-align: right !important;">
        <img style="width:500px; position: relative; left: 36px; top: 45px;"
          src="../assets/undraw_wind_turbine_x2k4.svg" />
      </div>
    </div>
  </nb-layout-footer>

  <nb-layout-footer class="white">
    <div class="row card-row">
      <div class="col">
        <div class="text-caption hint center">
          <ul class="text-nav">
            <li><a (click)="openCookie()">Manage Cookies</a></li>
            <li><a href="" routerLink="/cookies">Cookie Policy</a></li>
            <li class="mid"><a href="" routerLink="/terms-conditions">{{"TERMS_AND_CONDITIONS" | translate}}</a></li>
            <li class="socials">
              <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://smarttariffsmartcomparison.org/home&title=&summary=Try this new smart tariff smart comparison tool – it uses your own energy consumption data to find the best tariff matches.&source="
                target="_blank"> <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 25 25"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="social" fill="#274BDB">
                    <g id="post-linkedin">
                      <path
                        d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                        id="Shape" />
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://smarttariffsmartcomparison.org/home"
                target="_blank">
                <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="social" fill="#274BDB">
                    <g id="post-facebook">
                      <path
                        d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M17,2 L17,5 L15,5 C14.4,5 14,5.4 14,6 L14,8 L17,8 L17,11 L14,11 L14,18 L11,18 L11,11 L9,11 L9,8 L11,8 L11,5.5 C11,3.6 12.6,2 14.5,2 L17,2 L17,2 Z"
                        id="Shape"></path>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://twitter.com/intent/tweet?url=https://smarttariffsmartcomparison.org/home&text=Try this new smart tariff smart comparison tool – it uses your own energy consumption data to find the best tariff matches."
                target="_blank">
                <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="social" fill="#274BDB">
                    <g id="post-twitter">
                      <path
                        d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M15.7,7.3 C15.6,11.9 12.7,15.1 8.3,15.3 C6.5,15.4 5.2,14.8 4,14.1 C5.3,14.3 7,13.8 7.9,13 C6.6,12.9 5.8,12.2 5.4,11.1 C5.8,11.2 6.2,11.1 6.5,11.1 C5.3,10.7 4.5,10 4.4,8.4 C4.7,8.6 5.1,8.7 5.5,8.7 C4.6,8.2 4,6.3 4.7,5.1 C6,6.5 7.6,7.7 10.2,7.9 C9.5,5.1 13.3,3.6 14.8,5.5 C15.5,5.4 16,5.1 16.5,4.9 C16.3,5.6 15.9,6 15.4,6.4 C15.9,6.3 16.4,6.2 16.8,6 C16.7,6.5 16.2,6.9 15.7,7.3 L15.7,7.3 Z"
                        id="Shape"></path>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="mailto:support@hildebrand.co.uk" target="_blank"
                style="background-color:#274BDB; border-radius: 3px; position: relative; top: 1px; padding: 1px 0;"
                width="21px" height="21px">
                <svg xmlns="http://www.w3.org/2000/svg" style="position: relative; left: 2px; top: 1px;" width="21px"
                  height="21px" viewBox="0 0 30 30">
                  <g id="social" fill="#fff">
                    <g id="email">
                      <path
                        d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z"
                        id="Shape" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nb-layout-footer>

</nb-layout>