<div class="row">
    <div class="col" style="text-align: left;">
        <h4>How we use your data</h4>
    </div>
</div>

<div class="row card-row info-row" style="margin: 20px 0;">
    <div class="col" style="padding:40px; background-color: #fff; text-align: left;">

        <h6>Your energy consumption</h6>

        <p>To work out which is the most appropriate smart tariff for you we start by retrieving your energy consumption
            data.</p>

        <p>People with smart meters that are part of the <a href="/glossary/2">DCC</a> Infrastructure can give the
            Smart Tariff Smart Comparison tool access to their energy consumption data by providing their post code and
            the unique identifier on their Energy Display. The combination of the two pieces of information is unique
            and ensure that the data retrieved is only for the meters at that address.</p>

        <p>Once the tool has retrieved enough energy consumption history our models then review all the tariffs in our
            database and lists the best options according to the criteria you select.</p>

        <p>From the point you give us permission to capture your smart meter data, we will continue doing so until you
            <a href="/stop-data-collection">ask us to stop</a>. This means any time you want to come back in the future and
            check what a switch to a new tariff would do to your bills, you just need to login and your full data
            history from your smart meters will be immediately retrieved for the comparison chart.</p>

        <p>Smart Tariff Smart Comparison and Hildebrand Technology will not use your energy consumption data for any
            other purposes than those described above.</p>

        <p>Please read our <a href="/terms-conditions">Terms & Conditions</a> for a full description of our obligations.</p>

    </div>
</div>