<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Who we are</h4>
    </div>
</div>

<div class="row card-row info-row" style="margin: 20px 0;">
    <div class="col" style="padding:40px; background-color: #fff; text-align: left;">

        <h6>Research project</h6>

        <p>Smart Tariff Smart Comparison is a <a
                href="https://www.gov.uk/government/publications/smart-meter-enabled-tariffs-comparison-project-smarter-tariffs-smarter-comparisons"
                target="_blank">research project funded by the government</a> department for Business Energy
            and Industrial Strategy (BEIS). By funding the development of this prototype and the related research
            learnings, BEIS is giving industry a tool to encourage suppliers and low carbon technology providers to
            develop innovative new tariffs and solutions for the market. At the end of the project BEIS will publish
            reports and hold events to disseminate findings.</p>

        <p>The team responsible for developing the tool are recognised leaders in their fields:</p>

        <ul>
            <li><a href="https://www.daviesmckerr.com/" target="_blank">davies+mckerr</a> (consumer research)</li>
            <li><a href="https://www.hildebrand.co.uk/" target="_blank">Hildebrand Technology</a> (prototype delivery
                and smart meter data access)</li>
            <li><a href="https://loveexperience.co.uk/" target="_blank">Love Experience</a> (user experience design)
            </li>
            <li><a href="https://www.carbontrust.com/" target="_blank">The Carbon Trust</a> (policy and stakeholder
                engagement)</li>
            <li><a href="https://www.imperial.ac.uk/people/r.carmichael" target="_blank">Dr Richard Carmichael</a> of <a
                    href="https://www.imperial-consultants.co.uk/" target="_blank">Imperial Consultants</a> (subject
                expertise)</li>
            <li><a href="https://www.vitalenergi.co.uk/" target="_blank">Vital Energi</a> (programme management)</li>
        </ul>

        <p>Hildebrand is a DCC Other User that is registered with the <a href="https://smartenergycodecompany.co.uk/"
                target="_blank">Smart Energy Code Administrator</a>. This is why we are able to retrieve your smart
            meter data. Being a DCC Other User comes with a lot of Security and Privacy obligations which are described
            <a href="https://smartenergycodecompany.co.uk/your-security-and-privacy-obligations/"
                target="_blank">here</a>. Hildebrand is also ISO 27001 certified; your data is always treated with
            security and privacy.
        </p>

    </div>
</div>