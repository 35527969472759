<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Cookie Policy</h4>
    </div>
</div>

<div class="row card-row info-row" style="margin: 20px 0;">
    <div class="col" style="padding:40px; background-color: #fff; text-align: left;">

        <h6>Smart Tariff Smart Comparison Cookie Policy</h6>
        <p>Last updated: 29 January 2021</p>
        <p>Smart Tariff Smart Comparison (STSC) is provided by Hildebrand Technology Limited, Company number 05577050.
            To contact Hildebrand, please send an email to <a
                href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a>
        </p>
        <p>This Cookie Policy explains how Hildebrand Technology Ltd ("Company", "we", "us", and "our") uses cookies and
            similar technologies when you visit the website at <a href="https://smarttariffsmartcomparison.org">https://smarttariffsmartcomparison.org</a>, ("Websites"). It
            explains what these technologies are and why we use them, as well as your rights to control our use of them.
        </p>
        <p>We use cookies for two reasons on the STSC site:</p>
        <ol>
            <li>To keep a record when you consented to using cookies</li>
            <li>For research purposes to allow us to track what users interact with on the STSC site; the cookie data
                will allow us to analyse how people are using the site and identify what works and what doesn’t. Users
                are tracked anonymously.</li>
        </ol>
        <p>We do NOT record with cookies any of the personal information you provided including, your post code, your
            email address, your name or the EUI/GUID of your Energy Display.</p>

        <h6>What are cookies?</h6>
        <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website.
            Cookies are widely used by website owners in order to make their websites work, or to work more efficiently,
            as well as to provide reporting information.</p>
        <p>Cookies set by the website owner (in this case, Hildebrand Technology Ltd) are called "first party cookies".
        </p>
        <p>Cookies set by parties other than the website owner are called "third party cookies". Third party cookies
            enable third party features or functionality to be provided through the website.</p>

        <h6>Why do we use cookies?</h6>
        <p>We use first and third party cookies for the two reasons described at the start of this page. Some cookies
            are required for technical reasons in order for our website to operate (we need to record that you have
            agreed we can use the third party cookies), and we refer to these as "essential" or "strictly necessary"
            cookies.</p>
        <p>Other cookies (third party) enable us to track and how our users engage with the site so that in future we
            can enhance the experience on our Website. Third parties serve cookies through our Website for analytics
            purposes.</p>
        <p>We do not set any third party cookies for advertising or any purposes other than using the google cookies for
            research purposes. Hildebrand set the google third party cookies on your device when you visit the STSC
            website if the user accept cookies; we pass the cookies through to Google Analytics unless the user
            indicates they reject cookies.</p>
        <p>The specific types of first and third party cookies served through our Websites and the purposes they perform
            are described above at the start of this document.</p>

        <h6>How can I control cookies?</h6>
        <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by
            setting your preferences in the Cookie Consent Manager.</p>
        <p>The Cookie Consent Manager is offered to you when you first come to our web site. It can also be found on our
            website. If you choose to reject cookies, you may still use our website and we will NOT pass your usage
            information to google Analytics. You may also set or amend your web browser controls to accept or refuse
            cookies. As the means by which you can refuse cookies through your web browser controls vary from
            browser-to-browser, you should visit your browser's help menu for more information.</p>
        <p>The specific types of first and third party cookies served through our Websites and the purposes they perform
            are described in the table below:</p>

        <h6>Essential website cookies:</h6>
        <p>This cookie is strictly necessary to track whether you have given us permission to use cookies:</p>

        <ul>
            <li>Name: cookieconsent_status</li>
            <li>Purpose: Used to track whether permission has been given to use cookies.</li>
            <li>Provider: .smarttariffsmartcomparison.org</li>
            <li>Service: Smart Tariff Smart Comparison</li>
            <li>Country: United Kingdom</li>
            <li>Type: http_cookie</li>
            <li>Expires in: 1 year</li>
        </ul>

        <h6>First Party Cookies</h6>
        <p>These are cookies that are set by this website directly.</p>
        <p>User Login: We use cookies on this site to keep users logged in and to protect your information. Users who do
            not use cookies may not be able to access many of the glowmarkt features.</p>

        <h6>Third Party Cookies</h6>
        <p>Google Analytics: We use Google Analytics to collect information about visitor behaviour on our website.
            Google Analytics stores information about what pages you visit, how long you are on the site, how you got
            here and what you click on. This Analytics data is collected via a JavaScript tag in the pages of our site
            and is not tied to personally identifiable information. We therefore do not collect or store your personal
            information (e.g. your name or address) so this information cannot be used to identify who you are.</p>
        <p>You can find out more about Google's position on privacy as regards its analytics service at
            <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></p>

        <h6>How often will you update this Cookie Policy?</h6>
        <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies
            we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy
            regularly to stay informed about our use of cookies and related technologies.</p>
        <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

        <h6>Where can I get further information?</h6>
        <p>If you have any questions about our use of cookies or other technologies, please email us at
            <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a> or by post to:</p>

        <span>
            Hildebrand Technology Ltd<br />
            56 Mortimer Street, London<br />
            London, London W1S 2YZ<br />
            United Kingdom<br />
            Phone: (+44) 020 7486 1020
        </span>

    </div>
</div>