<div class="row">
    <div class="col" style="text-align: left;">
        <h4>Tell us to stop collecting your smart meter data</h4>
    </div>
</div>

<div class="row card-row info-row" style="margin: 20px 0;">
    <div class="col" style="padding:40px; background-color: #fff; text-align: left;">

        <h6>Any time</h6>

        <p>You have the right to tell us to stop collecting your smart meter data at any time. Please send an email to
            <a href="mailto:support@hildebrand.co.uk">support@hildebrand.co.uk</a> from the email address you used when
            you registered your account with Smart Tariff
            Smart Comparison and ask us to stop collecting your data.
        </p>

        <p>You can also ask us to delete your data history or maintain it, whatever you prefer.</p>

        <p>
            Hildebrand Technology Ltd is the technology company that developed and run the Smart Tariff Smart Comparison
            web site. Hildebrand is a DCC Other User that is registered with the <a
                href="https://smartenergycodecompany.co.uk" target="_blank">Smart Energy Code Administrator</a>. This is
            why we are able to retrieve your smart meter data. Being a DCC Other User comes with a lot of Security and
            Privacy obligations which are described a
            <a href="https://smartenergycodecompany.co.uk/your-security-and-privacy-obligations"
                target="_blank">here</a>. As part of our obligations, we will regularly email anyone whose data we are
            collecting to confirm that we still have your
            consent to do so.
        </p>

    </div>
</div>