import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConversationalForm } from 'conversational-form';
import { HttpClient } from '@angular/common/http';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NbDialogService } from '@nebular/theme';
import { SmartTariffComponent } from 'src/app/components/smart-tariff/smart-tariff.component';
import { ComparisonComponent } from 'src/app/components/comparison/comparison.component';
import { AppComponent } from 'src/app/app.component';
import { TermsConditionsModalComponent } from 'src/app/components/terms-conditions-modal/terms-conditions-modal.component';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  @ViewChild('myCF') myCF: ElementRef;
  cf: any;
  initialFormFields = [
    {
      'tag': 'select',
      'name': 'more_info',
      'id': 'more_info',
      'cf-questions': 'Smart Tariff, Smart Comparison connects to your smart meter to retrieve your energy consumption data&&We will show you a chart of your data history and what different smart tariffs would cost you&&The service is compatible with most smart meters. You will need to have your Energy Display in hand&&Would you like to know more about smart meters?',
      'cf-input-placeholder': 'Select',
      'isMultiChoice': false,
      'children': [
        {
          'tag': 'option',
          'cf-label': 'No',
          'value': 'No'
        },
        {
          'tag': 'option',
          'cf-label': 'Yes',
          'value': 'Yes'
        }
      ]
    }
  ];

  article;
  selectedProperty;
  selectedAdults;
  selectedChildren;
  selectedElecBillPeriod;
  selectedGasBillPeriod;
  showSmart = false;
  myMeterType: string;
  meterTypes: string[] = ["Smart", "Traditional"];

  postcodeCheckData = { postcode: "", eui: "" };
  XapplicationID = '6dc7e58b-4f10-4897-9a6b-a0971bee1235';

  registrationData = {
    "user": {
      "name": "",
      "username": "",
      "email": "",
      "password": "",
      "directoryId": "951cffa7-863f-4ae7-8f7e-ed682e690f91"
    },
    "applicationId": "6dc7e58b-4f10-4897-9a6b-a0971bee1235",
    "provisionInfo": {
      "postcode": "",
      "eui": ""
    }
  };

  selectedItemFormControl = new FormControl();
  subscriptionTerms: Subscription;

  constructor(
    private ccService: NgcCookieConsentService,
    private appComponent: AppComponent,
    private dialogService: NbDialogService,
    private dataService: DataService,
    private http: HttpClient,
  ) {

    this.subscriptionTerms = this.dataService.terms$.subscribe(
      resp => {
        this.closeTermsAndConditions(resp.accepted, resp.success, resp.error)
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    console.log('init', this)
    this.cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: this.submitCallback.bind(this),
        flowStepCallback: this.flowStepCallback.bind(this),
        preventAutoFocus: true,
        userImage: 'assets/arrow-up.svg',
        robotImage: 'assets/smart_bullet.svg'
      },
      tags: this.initialFormFields,

    });
    this.myCF.nativeElement.appendChild(this.cf.el);
  }

  ngOnChanges(): void {
  }

  openSmartTariffModal() {
    this.dialogService.open(SmartTariffComponent, {});
  }

  openComparisonModal() {
    this.dialogService.open(ComparisonComponent, {});
  }

  showTermsAndConditions(success, error) {
    this.dialogService.open(TermsConditionsModalComponent, {
      context: {
        successCall: success,
        errorCall: error
      }
    });
  }

  closeTermsAndConditions(accepted, success, error) {
    if (accepted) {
      this.flowStepCallback({ tag: { id: 'acceptTerms' } }, success, error)
    }
    else {
      this.cf.addRobotChatResponse("We acknowledge that you don't accept our Terms & Conditions; this means that you cannot proceed to the smart tariff results with your consumption data.");
      error();
    }
  }

  isPasswordStrong(password) {
    var errorList = {}
    var passwordMinLength = 7;
    var passwordMaxLength = 128;

    var numberOfErrors = 0;

    if (password.length < passwordMinLength) {
      errorList["PASSWORD_STRENGTH_MIN_LENGTH"] = {
        "errMsg": "The password must be at least 7 characters long.",
        "error": true
      };
      numberOfErrors += 1;
    }
    if (password.length > passwordMaxLength) {
      errorList["PASSWORD_STRENGTH_MAX_LENGTH"] = {
        "errMsg": "The password must be at most 128 characters long.",
        "error": true
      };
      numberOfErrors += 1;
    }
    if (/(.)\1{2,}/.test(password)) {
      errorList["PASSWORD_STRENGTH_MAX_SEQUENCE"] = {
        "errMsg": "The password may not contain sequences of three or more repeated characters.",
        "error": true
      };
      numberOfErrors += 1;
    }
    if (!/[a-z]/.test(password)) {
      errorList["PASSWORD_STRENGTH_CHARACTER_LOWERCASE"] = {
        "errMsg": "The password must contain at least one lowercase letter.",
        "error": true
      };
      numberOfErrors += 1;
    }
    if (!/[A-Z]/.test(password)) {
      errorList["PASSWORD_STRENGTH_CHARACTER_UPPERCASE"] = {
        "errMsg": "The password must contain at least one uppercase letter.",
        "error": true
      };
      numberOfErrors += 1;
    }
    if (!/[0-9]/.test(password)) {
      errorList["PASSWORD_STRENGTH_CHARACTER_NUMBER"] = {
        "errMsg": "The password must contain at least one number.",
        "error": true
      };
      numberOfErrors += 1;
    }
    if (!/[^A-Za-z0-9]/.test(password)) {
      errorList["PASSWORD_STRENGTH_CHARACTER_SPECIAL"] = {
        "errMsg": "The password must contain at least one special character.",
        "error": true
      };
      numberOfErrors += 1;
    }

    if (numberOfErrors > 0) {
      return errorList;
    }
    return null;
  }

  async flowStepCallback(dto, success, error) {
    console.log("flow step called " + dto.tag.id);
    console.log("value " + dto.tag.value);

    let additionalParams = {}

    var postcodecheckURL = "https://mapserver.glowpro.shop/postcodes/";

    if (dto.tag.id == 'more_info' || dto.tag.id == 'inputProceed') {
      if (dto.tag.value == 'Yes') {
        // this.openSmartTariffModal()
        this.cf.addTags([
          {
            // basic tag
            "tag": "input",
            "id": "inputProceed",
            "value": "Ok to go on?",
            "type": "text",
            "cf-input-placeholder": "next",
            "cf-questions": "Check out our <a href='/glossary/8'>smart meter</a> definition.",
          }
        ]);
        success();
      } else {
        this.cf.addTags([
          {
            // basic tag
            "tag": "input",
            "id": "inputPostcode",
            "type": "text",
            "cf-input-placeholder": "Your postcode",
            "cf-questions": "You can type answers to questions in the chat below, or view helpful examples&&By tapping on the up arrow you can navigate backwards to a previous step&&The next steps are quick – we need some brief details from you to get going&&Start by entering your postcode",
            "cf-error": "Doesn't look right"
          }
        ]);
        success();
      }
      additionalParams = { moreInfo: Array.isArray(dto.tag.value) ? dto.tag.value[0] : dto.tag.value }
    }

    if (dto.tag.id == 'inputPostcode') {

      var re = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]*[0-9][A-Z]{2}$/i;
      let tmpPC = String(dto.tag.value).toUpperCase().replace(/\s/g, '');
      console.log(tmpPC);

      if (!re.test(tmpPC)) {
        this.sendGAEvent(dto.tag.id, { validPostcode: 'No' })
        this.cf.addRobotChatResponse("Postcode should be like W1T 3JL&&Give it another try ...");
        error();
      } else {

        postcodecheckURL = postcodecheckURL + tmpPC;

        this.http.jsonp(postcodecheckURL, 'callback').subscribe((data: any) => {
          console.log(data);
          if (data.status == 200) {
            localStorage.setItem('postcode', tmpPC);
            this.postcodeCheckData.postcode = tmpPC;
            this.registrationData.provisionInfo.postcode = tmpPC;
            this.cf.addRobotChatResponse("Thanks, that looks like a valid postcode in " + data.result.admin_district);
            this.cf.addTags([
              {
                // basic tag
                "tag": "select",
                "id": "example_eui",
                "type": "select",
                "cf-input-placeholder": "Select",
                "cf-questions": "It looks like we can access your energy data but first we need a unique code that is on your Energy Display (this is a security check; it proves your display is the one for your home and your smart meters)&&The code will be labelled as GUID or EUI; it is 16 characters long and a combination of numbers and letters with dashes or colons between each pair (which you don't have to enter)&&It will either be a sticker on the bottom or back of the display - or in the Settings Menu. Would you like to view examples?",
                "children": [
                  {
                    "tag": "option",
                    "cf-label": "No",
                    "value": "No"
                  },
                  {
                    "tag": "option",
                    "cf-label": "Yes",
                    "value": "Yes"
                  }
                ],
                "cf-error": "Doesn't look right"
              }
            ]);
            success();
          } else {
            this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct postcode");
            this.cf.addRobotChatResponse("It should be the one on your bill.");
            error();
          }
          if (data.status == 200) {
            additionalParams = { postcode: tmpPC }
          }

          this.sendGAEvent(dto.tag.id, { validPostcode: data.status == 200 ? 'Yes' : 'No', ...additionalParams })
        }, (err) => {
          console.log("Error in postcode checking");
          this.sendGAEvent(dto.tag.id, { validPostcode: 'No' })
          this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct potcode");
          this.cf.addRobotChatResponse("Check and try again.");
          error();
        });

      }

      additionalParams = { postcode: dto.tag.value }
    }

    if (dto.tag.id == 'example_eui') {
      if (dto.tag.value == 'Yes') {
        this.cf.addTags([
          {
            // basic tag
            "tag": "input",
            "id": "example_eui",
            "value": "Glow Display",
            "type": "radio",
            "cf-label": "Glow Display",
            "cf-image": "assets/glow_eui.jpg",
            "cf-input-placeholder": "OK to go on?",
            "cf-questions": "Here are some examples&&Select one to continue"
          }, {
            "tag": "input",
            "id": "example_eui",
            "value": "Chameleon",
            "type": "radio",
            "cf-label": "Chameleon",
            "cf-image": "assets/cham_eui.jpg",
          }
        ]);
        success();
      } else {
        this.cf.addTags([
          {
            // basic tag
            "tag": "input",
            "id": "inputEUI",
            "type": "text",
            "cf-input-placeholder": "Enter In Home Display code",
            "cf-questions": "If you don’t have a Smart Meter, or no longer have the display here are some <a href='/faqs/1'>next steps</a>&&Now please enter the code from the In Home Display",
            "cf-error": "Doesn't look right"
          }
        ]);
        success();
      }
      additionalParams = { exampleEUI: dto.tag.value[0] }
    }

    if (dto.tag.id == 'inputEUI') {

      console.log("My EUI " + dto.tag.value);
      var cleaneui = dto.tag.value.trim().toUpperCase().replace(/\s|:|-/g, '');

      var re = /[A-F0-9-]{16}/;

      if (re.test(cleaneui)) {
        console.log("My EUI " + cleaneui);
        if (!re.test(cleaneui)) {
          this.cf.addRobotChatResponse("Make sure to use the number zero&&not O as in Oh my!");
          this.cf.addRobotChatResponse("Give it another try ...");
          error();

        } else {
          this.postcodeCheckData.eui = cleaneui;

          var euicheckURL = "https://apil.glowmarkt.com/api/v0-1/eligibility/checksmetsihd/postcode";

          this.http.post(euicheckURL, JSON.stringify(this.postcodeCheckData), {
            headers: {
              'applicationid': this.XapplicationID,
              'X-GLOW-Version': '0',
              'Content-Type': 'application/json'
            }
          }).subscribe((data: any) => {
            console.log(data);

            if (data.valid == true && data.status == "OK") {
              this.registrationData.provisionInfo.postcode = this.postcodeCheckData.postcode;
              this.registrationData.provisionInfo.eui = this.postcodeCheckData.eui;
              this.cf.addTags([
                {
                  // basic tag
                  "tag": "select",
                  "id": "viewTerms",
                  "type": "select",
                  "cf-input-placeholder": "Select",
                  "cf-questions": "Looks like we can get started! While we are fetching your consumption data please read our Terms and Conditions to understand the implications of allowing the Smart Tariff Smart Comparison site to retrieve your energy data.",
                  "children": [
                    {
                      "tag": "option",
                      "cf-label": "View Terms and Conditions",
                      "value": "I accept the Terms and Conditions"
                    },
                  ],
                  "cf-error": "Unable to proceed"
                },
              ]);

              success();
            } else {
              this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct EUI");
              this.cf.addRobotChatResponse("It should be on your In Home Display.");
              error();
            }
          }, (error) => {
            this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct EUI");
            this.cf.addRobotChatResponse("It should be on your In Home Display.");
            error();
          });
        }

      } else {
        this.cf.addRobotChatResponse("An EUI should be 16 or 23 characters long&&usually has dashes.");
        this.cf.addRobotChatResponse("Give it another try ...");
        error();
      }

      additionalParams = { validEUI: re.test(cleaneui) ? 'Yes' : 'No' }
    }

    if (dto.tag.id === 'viewTerms') {
      this.showTermsAndConditions(success, error)
    }

    if (dto.tag.id === 'acceptTerms') {
      this.cf.addTags([
        {
          // basic tag
          "tag": "input",
          "id": "myname",
          "type": "text",
          "cf-questions": "We would like to create an account for you for a couple of reasons:&&1. Retrieving your data can take several minutes. Rather than making you wait, we’ll send you an email when your data is ready. You can login here at your convenience to see up to 13 months of your energy consumption history and matching tariffs.&&2. With an account you can come back another time without needing to go through this process again and test different tariffs as your needs change.&&If you already have an account, <a href='/login'>Login</a> to see your results.&&Let’s get started with creating your account:&&Please enter your full name",
          "cf-input-placeholder": "Your full name",
          "cf-error": "No name given"
        },
        {
          // basic tag
          "tag": "input",
          "id": "myemail",
          "type": "text",
          "cf-questions": "And the email address you’d like to use",
          "cf-input-placeholder": "Your email",
          "cf-error": "Sorry doesn't look like an email address"
        },
        {
          // basic tag
          "tag": "input",
          "id": "mypassword",
          "type": "password",
          "cf-questions": "Now create a password&&Passwords must include:&&- Numbers, uppercase & lowercase letters&&- Special characters&&- At least 7 characters",
          "cf-input-placeholder": "Choose a password",
          "cf-error": "Sorry, your password is not strong enough."
        }
      ]);
      success()
    }

    if (dto.tag.id == "myname") {
      if (dto.tag.value.length > 0) {
        console.log("My name " + dto.tag.value);
        // registrationData.provisionInfo.mpan = mpanCheckData.mpxn;
        this.registrationData.user.name = dto.tag.value;
        success();
      } else {
        this.cf.addRobotChatResponse("Name should not be blank, try again.");
        error();
      }
    }

    if (dto.tag.id == "myemail") {
      console.log("My email");
      // var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      let emailaddress = dto.tag.value.toLowerCase();

      if (emailaddress) {
        console.log("My email " + emailaddress);
        this.registrationData.user.email = emailaddress;
        this.registrationData.user.username = emailaddress;
        success();
      } else {
        this.cf.addRobotChatResponse("Select your email above and edit.");
        error();
      }
    }

    if (dto.tag.id == "mypassword") {
      var regURL = "https://api.glowmarkt.com/api/v0-1/register/smart-meter-postcode-eui";

      console.log("My password");
      console.log("My password " + dto.tag.value);
      let passwordResponse = this.isPasswordStrong(dto.tag.value)
      if (!passwordResponse) {
        this.registrationData.user.password = dto.tag.value;

        this.http.post(regURL, JSON.stringify(this.registrationData), {
          headers: {
            'applicationid': this.XapplicationID,
            'X-GLOW-Version': '0',
            'Content-Type': 'application/json'
          }
        }).subscribe((data: any) => {
          console.log('data', data);
          if (data && data.data) {
            console.log('data.data', data.data);
            localStorage.setItem('smartMeterRegisterData', data.data);
          }
          if (data.status == "unverified") {
            additionalParams = { accountCreated: 'Yes' }
            // this.cf.addRobotChatResponse("Thank you&&You will be getting an email to verify your account for the future.&&Your <a href='/results'>results</a> to compare.");
            this.cf.addRobotChatResponse("Thank you&&You will be getting an email asking you to verify your account - once you've done that you can login any time.");
            // this.cf.addRobotChatResponse("There are 49 Smart Tariffs available for you to compare");
            // this.cf.addTags([
            //   {
            //     // basic tag
            //     "tag": "input",
            //     "id": "example_compare",
            //     "value": "octopus",
            //     "type": "radio",
            //     "cf-label": "Smart rating: 92 <br/>Annual cost: £779",
            //     "cf-image": "assets/octopus_stars.png",
            //     "cf-input-placeholder": "next",
            //     "cf-questions": "Here are some examples"
            //   }, {
            //     "tag": "input",
            //     "id": "example_compare",
            //     "value": "bulb",
            //     "type": "radio",
            //     "cf-label": "Smart rating: 75 <br/>Annual cost: £1,034",
            //     "cf-image": "assets/bulb_stars.png",
            //     "cf-input-placeholder": "next"
            //   }, {
            //     "tag": "input",
            //     "id": "example_compare",
            //     "value": "sse",
            //     "type": "radio",
            //     "cf-label": "Smart rating: 69 <br/>Annual cost: £1,058",
            //     "cf-image": "assets/sse_stars.png",
            //     "cf-input-placeholder": "next"
            //   }
            // ]);
            this.appComponent.doLogOut()
            success();
          } else {
            additionalParams = { accountCreated: 'No' }
            this.cf.addRobotChatResponse("Sorry, looks like something went wrong.&&Email support@hildebrand.co.uk and we can help you out.");
          }
          this.sendGAEvent(dto.tag.id, additionalParams)
        }, (err => {
          console.log(err);
          console.log(err.error.error);
          if (err.error.error == "username already exists") {
            additionalParams = { accountCreated: 'No - Account already exists' }
            this.cf.addRobotChatResponse("That account is already taken&&Login to <a href='/login'>STSC</a> to see your results.");
            error();
          } else {
            additionalParams = { accountCreated: 'No' }
            this.cf.addRobotChatResponse("Looks like an account error on creation.");
            error();
          }
          this.sendGAEvent(dto.tag.id, additionalParams)
        }))
      } else {
        additionalParams = { accountCreated: 'No' }
        this.sendGAEvent(dto.tag.id, additionalParams)
        for (const property in passwordResponse) {
          this.cf.addRobotChatResponse(passwordResponse[property].errMsg);
        }
        error();
      }

    }

    // if (dto.tag.id == "example_compare") {
    //   additionalParams = { viewComparisonTable: 'Yes' }
    //   this.cf.addTags([
    //     {
    //       // basic tag
    //       "tag": "input",
    //       "id": "end_compare",
    //       "type": "text",
    //       "cf-questions": "Or view all 49 matches in a <a href='/results'>comparison table</a>",
    //       "cf-input-placeholder": "Click link for more",
    //       "cf-error": "No name given"
    //     }
    //   ]);
    //   success();
    // }

    if (dto.tag.id != "mypassword" && dto.tag.id != 'inputPostcode') {
      this.sendGAEvent(dto.tag.id, additionalParams)
    }

  }

  submitCallback() {
    console.log('finished')
    // var formDataSerialized = this.cf.getFormData(true);
    // console.log("Formdata, obj:", formDataSerialized);
    // this.cf.addRobotChatResponse("You are done. Check the dev console for form data output.")
  }

  sendGAEvent(stepId, additionalParams) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      console.log('sendGAEvent', { event: 'flowStep', step: stepId, ...additionalParams })
      window.dataLayer.push({
        event: 'flowStep',
        step: stepId,
        ...additionalParams
      })
    }
  }

}