<nb-card *ngIf="showResults">
    <nb-layout-header>
        <nb-layout-column style="padding-top: 0; padding-bottom: 0;">
            <h4>{{"CONNECTED_TO_SMART_METER" | translate}} <strong>{{postcode}}</strong></h4>
        </nb-layout-column>
    </nb-layout-header>

    <div class="row btn-row" *ngIf="selectedResult.length > 0">
        <div class="col button-container">
            <p style="color: #8f9bb3; font-weight: 600;">Selected Tariffs</p>
            <div *ngFor="let tariff of selectedResult; index as i;" class="selected-tariff small">
                <nb-icon style="color: white;" class="close" icon="close-outline"
                    (click)="removeTariff(i, tariff.fkid)">
                </nb-icon>
                {{tariff.display_name}} - <span *ngIf="tariff.save">Save </span><span *ngIf="!tariff.save">Extra
                </span>{{tariff.annualCost}} annually
            </div>
        </div>
    </div>


    <div class="row btn-row" *ngIf="!loadingData">
        <div class="col button-container">

            <p style="color: #8f9bb3; font-weight: 600;">How much can I change when I consume energy?
                <nb-icon [nbPopover]="templateRef" style="color:#222b45; position: relative; top: 2px;" icon="info">
                </nb-icon>
            </p>

            <ng-template #templateRef style="background-color: 222b45; color: #fff;">
                <ul>
                    <li><strong>Not at all</strong> - clears selection</li>
                    <li><strong>Energy Flexibility</strong> - shifts your energy from high usage periods to
                        low usage periods (18:30-20:30 to 00:00-02:00)</li>
                    <li><strong>Add Electric Vehicle</strong> - adds EV consumption to your existing
                        consumption</li>
                </ul>
            </ng-template>

            <div class="web-flex-btn">
                <div class="web-flex-btn" *ngFor="let btn of graphFilters;"
                    [ngClass]="btn.value==selectedGraphFilter ? 'graphFilter active' : 'graphFilter' ">
                    <button (click)="selectGraphFilter(btn.value)" nbButton>{{btn.displayName}}</button>
                </div>
            </div>

            <div class="mob-flex-btn">
                <div style="display: inline-block;" [ngClass]="'none'==selectedGraphFilter ? 'active' : '' ">
                    <button (click)="selectGraphFilter('none')" nbButton>Clear</button>
                </div>
                <form [formGroup]="form" style="display: inline-block;">
                    <nb-select formControlName="flex" (selectedChange)="changeMobFlexibility($event)"
                        placeholder="Select" style="margin-right: 10px; position: relative; top:2px;">
                        <nb-option value="">Flexibility</nb-option>
                        <nb-option value="low">10% shift</nb-option>
                        <nb-option value="medium">20% shift</nb-option>
                        <nb-option value="high">30% shift</nb-option>
                    </nb-select>
                </form>
                <div style="display: inline-block;" [ngClass]="'smart'==selectedGraphFilter ? 'active' : '' ">
                    <button (click)="selectGraphFilter('smart')" nbButton>+ EV</button>
                </div>
            </div>
        </div>
    </div>


    <div class="row card-row">
        <div class="col">
            <nb-tabset (changeTab)="selectGraphView($event)">
                <nb-tab tabTitle="Monthly" [nbSpinner]="loadingData" nbSpinnerStatus="primary" nbSpinnerSize="giant">
                    <div *ngIf="loadingData" style="min-height: 300px;"></div>
                </nb-tab>
                <nb-tab tabTitle="Average Daily" [nbSpinner]="loadingData" nbSpinnerStatus="primary"
                    nbSpinnerSize="giant">
                    <div *ngIf="loadingData" style="min-height: 300px;"></div>
                </nb-tab>
            </nb-tabset>

            <div *ngIf="!loadingData">


                <div class="row" *ngIf="selectedGraphView=='month'">
                    <div class="col chart" *ngIf="filterResults && monthlyConsumptionData">
                        <app-monthly-graph [selectedGraphFilter]="selectedGraphFilter" [selectedResult]="selectedResult"
                            [filterResults]="filterResults" [evMonthlyConsumptionData]="evMonthlyConsumptionData"
                            [monthlyConsumptionData]="monthlyConsumptionData" [annualCostArr]="annualCostArr">
                        </app-monthly-graph>
                    </div>
                </div>

                <div class="row" *ngIf="selectedGraphView=='day'">
                    <div class="col chart" *ngIf="selectedGraphFilter">
                        <app-average-daily [selectedGraphFilter]="selectedGraphFilter"></app-average-daily>
                    </div>
                </div>

                <div class="row btn-row">
                    <div class="col">
                        <button (click)="downloadBinary()" nbButton style="margin-right: 10px;">
                            <nb-icon icon="download-outline"></nb-icon> Binary Data
                        </button>
                        <button (click)="downloadCSV()" nbButton>
                            <nb-icon icon="download-outline"></nb-icon> CSV Data
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nb-card>

<div class="row" *ngIf="filterResults">
    <div class="col">
        <nb-card>
            <nb-card-header>{{"SELECT_A_TARIFF" | translate}}<nb-icon
                    nbTooltip="Switch from sort by cost, to sort by score then cost" class="float-right" icon="info">
                </nb-icon>
                <nb-toggle (click)="toggleSort($event)" class="float-right"></nb-toggle>
            </nb-card-header>

            <nb-tabset style="margin-top: 20px;">
                <nb-tab tabTitle="Best Tariffs" style="overflow: hidden;">
                    <nb-card-body style="overflow: hidden;">
                        <div class="row">
                            <div class="col" *ngFor="let result of filterResults; index as i;">
                                <nb-card size="small" [accent]="result.selected ? 'info' : ''"
                                    [ngClass]="result.selected ? 'results_selected best-card' : 'best-card' ">

                                    <nb-card-body (click)="selectTariff(result)">
                                        <div class="media-body">
                                            <stsc-badge class="float-right" *ngIf="result.tariff && result.tariff.score"
                                                [value]="result.tariff.score"></stsc-badge>
                                            <span class="results_label_top">{{"TARIFF" | translate}}</span>
                                            <span class="results_display_name">{{result.displayname}}</span>

                                            <span class="results_label">{{"ESTIMATED_COST" | translate}}</span>
                                            <span class="results_display">{{"MONTHLY_GBP" | translate}} {{
                                                (result.annualcost/12) | number:'1.0-0'}}
                                                / {{"ANNUAL_GBP" | translate}} {{result.annualcost |
                                                number:'1.0-0'}}</span>
                                            <span class="results_label">{{result.description}}</span>
                                        </div>
                                    </nb-card-body>

                                    <nb-card-footer
                                        *ngIf="result.tariff && result.tariff._flat && result.tariff._flat.rate && result.tariff.standingcharge && result.tariff.type!='dynamic' && result.tariff.type!='tou'">
                                        <div class="row">
                                            <div class="col text-center">
                                                <button nbButton (click)="openRateModal(result)" status="primary">View
                                                    Rates</button>
                                            </div>
                                        </div>
                                    </nb-card-footer>

                                    <nb-card-footer
                                        *ngIf="result.tariff && result.tariff._tou && (result.tariff.type=='dynamic' || result.tariff.type=='tou')">
                                        <div class="row">
                                            <div class="col text-center">
                                                <button nbButton (click)="openTOURateModal(result)"
                                                    status="primary">View Rates</button>
                                            </div>
                                        </div>
                                    </nb-card-footer>

                                    <nb-card-footer class="results_footer">
                                        <div class="row">
                                            <div class="col">
                                                <img height="30px"
                                                    *ngIf="result.tariff.brand_image=='/assets/affect_energy.svg'"
                                                    src="/assets/affect.png" />
                                                <img height="30px"
                                                    *ngIf="result.tariff.brand_image!='/assets/affect_energy.svg'"
                                                    [src]="result.tariff.brand_image" />&nbsp;
                                                <stsc-trust *ngIf="result.tariff.brand_score"
                                                    [value]="result.tariff.brand_score">
                                                </stsc-trust>
                                                <nb-icon style="position: absolute; right: 10px; top: 5px;"
                                                    (click)="likeTariff(result)" *ngIf="!result.liked"
                                                    class="likeTariffIcon" icon="heart-outline" status="basic">
                                                </nb-icon>
                                                <nb-icon style="position: absolute; right: 10px; top: 5px;"
                                                    (click)="unlikeTariff(result)" *ngIf="result.liked"
                                                    class="unlikeTariffIcon" icon="heart" status="danger"
                                                    nbTooltip="Liked">
                                                </nb-icon>
                                            </div>
                                        </div>
                                    </nb-card-footer>
                                </nb-card>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col sliderimage">
                                <img class="middleimage" src="/assets/undraw_mobile_testing_reah.svg" />
                            </div>
                            <div class="col">
                                <div class="results_question_first" innerHTML='{{"GREEN_ENERGY_SLIDER_Q" | translate}}'>
                                </div>
                                <ngx-slider [(value)]="green_value" (mouseup)="greenChange()" [options]="green_options">
                                    <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement"
                                        let-content="content">
                                        <div class="h-100" tooltipClass="question-class" [ngbTooltip]="tooltip"
                                            [placement]="placement">{{content}}</div>
                                    </ng-template>
                                </ngx-slider>
                                <div class="results_question" innerHTML='{{"CUSTOMER_SERVICE_SLIDER_Q" | translate}}'>
                                </div>
                                <ngx-slider [(value)]="service_value" (mouseup)="serviceChange()"
                                    [options]="service_options">
                                    <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement"
                                        let-content="content">
                                        <div class="h-100" tooltipClass="question-class" [ngbTooltip]="tooltip"
                                            [placement]="placement">{{content}}</div>
                                    </ng-template>
                                </ngx-slider>
                                <div class="results_question" innerHTML='{{"LOWEST_PRICE_SLIDER_Q" | translate}}'></div>
                                <ngx-slider [(value)]="price_value" (mouseup)="priceChange()" [options]="price_options">
                                    <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement"
                                        let-content="content">
                                        <div class="h-100" tooltipClass="question-class" [ngbTooltip]="tooltip"
                                            [placement]="placement">{{content}}</div>
                                    </ng-template>
                                </ngx-slider>
                            </div>
                        </div>
                    </nb-card-body>
                </nb-tab>
                <nb-tab tabTitle="All {{ resultscount }} Results">
                    <div class="col">
                        <nb-card-body>
                            <div class="row">
                                <div class="col">
                                    <ng2-smart-table #table *ngIf="results" (userRowSelect)="graphFullResults($event)"
                                        [settings]="settings" [source]="results"></ng2-smart-table>
                                </div>
                            </div>
                        </nb-card-body>
                    </div>
                </nb-tab>
            </nb-tabset>

        </nb-card>
    </div>
</div>

<nb-card *ngIf="showNoData">
    <nb-card-header>
        <h4>{{"NO_CONSUMPTION_DATA_HEADING" | translate}}</h4>
    </nb-card-header>
    <nb-card-body>
        <p>{{"NO_CONSUMPTION_DATA_TEXT" | translate}}</p>
        <p *ngIf="loadtime>3600">It may take some time to retrieve your data.</p>
    </nb-card-body>
</nb-card>
<nb-card *ngIf="showNoData && timeRemaining && loadtime<3600" [nbSpinner]="true" nbSpinnerStatus="primary">
    <nb-card-body>
        Estimated: <strong>{{timeRemaining}}</strong>
    </nb-card-body>
</nb-card>

<nb-card *ngIf="showResultError">
    <nb-card-header>
        <h4>{{"RESULT_ERROR_HEADING" | translate}}</h4>
    </nb-card-header>
    <nb-card-body>
        <p>{{"RESULT_ERROR_TEXT" | translate}}</p>
    </nb-card-body>
</nb-card>